import React from 'react'

export default function HubsportForm ({ formEmbed }) {
    const hubspotForm = React.createRef()


    const decodeFormHtml = (html) => {
        const test = document.createElement('div')
        test.innerHTML = html // Parse Script HTML
        return Array.prototype.map.call(test.getElementsByTagName('script'), (el) => (
            {
                script: el.textContent,
                src: el.src
            }
        ))
    }

    React.useEffect(() => {
        const scriptList = decodeFormHtml(formEmbed)
        if (scriptList[0] && scriptList[0].src && hubspotForm.current !== null) {
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = scriptList[0].src
            script.onload = () => {
                for (const hubspotScript of scriptList) {
                    if (hubspotScript.script) {
                        const formScript = document.createElement('script')
                        formScript.type = 'text/javascript'
                        formScript.innerHTML = hubspotScript.script;
                        hubspotForm.current?.appendChild(formScript)
                    }
                }
            }
            hubspotForm.current?.appendChild(script)
        }
    }, [formEmbed])


    return (
        <div ref={hubspotForm} />
    )
}
